import React, { useState } from "react";
import PropTypes from "prop-types"
import { Link, StaticQuery, graphql } from "gatsby"
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import './header.css';

import { useLayoutEffect, useRef } from 'react';
import Language from "./changer";

const isBrowser = typeof window !== `undefined`;

const getScrollPosition = ({ element, useWindow }) => {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body,
    position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
};

export const useScrollPosition = (effect, deps, element, useWindow, wait) => {
  const position = useRef(getScrollPosition({ useWindow }));
  let throttleTimeout = null;

  const callBack = () => {
    const currentPosition = getScrollPosition({ element, useWindow });

    effect({ previousPosition: position.current, currentPosition: currentPosition });
    position.current = currentPosition;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait && !throttleTimeout) throttleTimeout = setTimeout(callBack, wait);
      else callBack();
    };

    window.addEventListener(`scroll`, handleScroll);

    return () => window.removeEventListener(`scroll`, handleScroll);
  }, deps);
};

const query = graphql`
query {
    allStrapiMenu(filter: {locale: {eq: "pt"}}, sort: {fields: Order, order: ASC}) {
      nodes {
        Name
        Section_id
      }
    }
  }
`;

const Header = ({ siteTitle }) => {
    const [scroll, setScroll] = useState(0);

    useScrollPosition(function setScrollPosition({ currentPosition }) {
        setScroll(currentPosition.y);
    });

    let isActive = false;
    let header = 'header';
    return (
        <div className={`header-holder ${scroll < 0 ? 'fixed' :''  }`}>
            <input type="checkbox" id="toggle" />
            <header className={`${header} ${isActive ? 'header--active' : ''}`}>
                <div className="content">
                    <div className="header__logo">
                        <Link to="/">
                            <svg width="171" height="51" viewBox="0 0 171 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M50.9873 13.7545H64.0541L92.2416 49.5042H79.1748L50.9873 13.7545Z" fill="#353535" />
                                <path d="M159.957 0.889252H171V49.5041H159.957V0.889252Z" fill="#353535" />
                                <path
                                    d="M144.866 27.5682C144.926 23.9517 142.574 20.7799 138.377 20.7799C134.806 20.7799 131.115 23.0328 130.877 27.5682H144.866ZM154.57 43.3384C151.027 47.9034 144.717 50.4231 138.824 50.4231C129.537 50.4231 119.477 44.287 119.477 32.0443C119.477 21.5506 126.888 12.8355 138.824 12.8355C149.152 12.8355 156.296 19.2977 156.266 31.6293V35.0976H130.877C131.859 39.5737 134.597 42.0341 139.211 42.0341C142.544 42.0637 145.759 40.7298 148.111 38.3583L154.57 43.3384Z"
                                    fill="#353535" />
                                <path
                                    d="M117.602 21.9952H108.077L108.136 35.4532C108.166 39.9887 108.642 42.0044 112.78 42.0044C114.268 42.0044 116.59 41.7376 117.602 41.1447V49.5041C115.31 50.2156 112.274 50.4231 109.982 50.4231C101.677 50.4231 97.0043 46.8659 97.0043 39.7219V21.9952H91.8252L108.077 3.73502V13.7544H117.631V21.9952H117.602Z"
                                    fill="#353535" />
                                <path
                                    d="M55.0352 41.2337C54.5887 40.6112 54.2018 39.9294 53.9041 39.2179L40.6884 7.14399C38.0096 3.64609 32.3245 4.44646 29.0801 10.7605C29.7349 12.3315 40.1527 37.6765 40.9266 39.544C43.9031 46.6287 47.5642 50.4231 55.1542 50.4231C60.9584 50.4231 63.9944 47.7552 66.5245 44.9391C66.5542 44.9094 58.8749 46.5991 55.0352 41.2337Z"
                                    fill="url(#paint0_linear)" />
                                <path
                                    d="M40.6888 7.14401C40.6888 7.14401 37.7123 0 29.9734 0C23.8715 0 19.2877 2.69753 16.7875 8.74475L0 49.5042H3.69086C9.79269 49.5042 14.1681 46.8659 16.1624 42.0044C16.6982 40.7001 26.9671 15.7998 28.5149 12.0351C28.6935 11.5905 28.9018 11.1458 29.1102 10.7308C32.3248 4.44648 38.0099 3.64611 40.6888 7.14401Z"
                                    fill="#F47920" />
                                <path
                                    d="M86.8842 13.7545C83.4017 13.7545 80.0085 14.377 76.7641 17.9935L59.7087 37.1726C57.8335 39.2773 56.4643 40.4927 55.0059 41.2337C58.8753 46.5695 66.5547 44.9095 66.5547 44.9095L94.2362 13.7545H86.8842Z"
                                    fill="url(#paint1_linear)" />
                                <defs>
                                    <linearGradient id="paint0_linear" x1="56.4077" y1="48.9467" x2="35.9048" y2="-1.88174"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F47920" />
                                        <stop offset="0.3" stopColor="#F1771F" />
                                        <stop offset="0.49" stopColor="#E9711D" />
                                        <stop offset="0.64" stopColor="#DB6719" />
                                        <stop offset="0.77" stopColor="#C75913" />
                                        <stop offset="0.89" stopColor="#AD470C" />
                                        <stop offset="1" stopColor="#8D3103" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear" x1="86.6712" y1="13.7471" x2="55.4479" y2="49.677"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#F47920" />
                                        <stop offset="0.3" stopColor="#F1771F" />
                                        <stop offset="0.49" stopColor="#E9711D" />
                                        <stop offset="0.64" stopColor="#DB6719" />
                                        <stop offset="0.77" stopColor="#C75913" />
                                        <stop offset="0.89" stopColor="#AD470C" />
                                        <stop offset="1" stopColor="#8D3103" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </Link>
                    </div>
                    <div className="header__button">
                        <label for="toggle"><span></span></label>
                    </div>
                    <div className="header__menu">
                        <StaticQuery query={query} render={(data) => (
                            <ul>
                                {data.allStrapiMenu.nodes.map((value) => {
                                    return <li for="toggle">
                                        <AnchorLink onAnchorLinkClick={() => { if (document.getElementById('toggle')) { document.getElementById('toggle').checked = false } }} to={value.Section_id.replace('/', '')}>{value.Name}</AnchorLink>
                                    </li>
                                })}
                            </ul>
                        )} />
                    </div>
                    <Language language="pt" />
                </div>
            </header>
        </div>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
